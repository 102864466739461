import React, { Fragment, useState } from 'react'
import { Modal } from 'react-bootstrap';

export default function CardModal(props) {

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const imgStyle = {
        backgroundImage: "url( /img/cards" + props.card.url + ")",
        backgroundSize: "contain"
    }

    const handleAdd = (button) => {
        const input = props.card
        if (props.keyTab === 'Mazo') {
            const auxCards = props.cards.map(card => { return card })
            const auxVars = props.dataVars
            auxCards.push(input.identifier)
            let exists = false
            const auxExists = (props.newDeck).map(card => {
                if (card.identifier === input.identifier) {
                    card.qty += 1
                    exists = true
                }
                return card
            })
            const auxDeck = auxExists
            if (!exists) {
                input.qty = 1
                auxDeck.push(input)
            }

            let auxImages = auxDeck.map(card => {
                return { value: card.url, label: card.name }
            })

            auxVars.cards = auxCards
            props.setDataVars(auxVars)
            props.setCards(auxCards)
            props.setNewDeck(auxDeck)
            props.setDeckImages(auxImages)
        }
        if (props.keyTab === 'Banquillo') {
            const auxBench = props.bench.map(card => { return card })
            const auxVars = props.dataVars
            auxBench.push(input.identifier)
            let exists = false
            const auxExists = (props.benchDeck).map(card => {
                if (card.identifier === input.identifier) {
                    card.qty += 1
                    exists = true
                }
                return card
            })
            const auxDeckBench = auxExists
            if (!exists) {
                input.qty = 1
                auxDeckBench.push(input)
            }

            auxVars.bench = auxBench
            props.setDataVars(auxVars)
            props.setBench(auxBench)
            props.setBenchDeck(auxDeckBench)
        }

    }

    const handleRemove = (button) => {
        if (props.keyTab === 'Mazo') {
            const actualCard = props.card
            const auxCards = props.cards
            const auxVars = props.dataVars
            let auxDeck = props.newDeck
            let auxDeck2 = []
            if (actualCard.qty > 1) {
                auxDeck2 = auxDeck.map(card => {
                    if (card.identifier === actualCard.identifier)
                        card.qty -= 1
                    return card
                })
            }
            else {
                auxDeck2 = auxDeck.filter(card => {
                    return (card.qty > 1 || (card.qty === 1 && card.identifier !== actualCard.identifier))
                })
            }
            props.setNewDeck(auxDeck2)

            let done = false
            let cardIndex = 0
            let newCards = auxCards.map(card => {
                if (!done) {
                    if (card === actualCard.identifier) {
                        done = true
                    }
                    else
                        cardIndex += 1
                }
                return card
            })

            newCards.splice(cardIndex, 1)
            props.setCards(newCards)
            auxVars.cards = newCards
            props.setDataVars(auxVars)
        }
        if (props.keyTab === 'Banquillo') {
            const actualCard = props.card
            const auxBench = props.bench
            const auxVars = props.dataVars
            let auxDeckBench = props.benchDeck
            let auxDeckBench2 = []
            if (actualCard.qty > 1) {
                auxDeckBench2 = auxDeckBench.map(card => {
                    if (card.identifier === actualCard.identifier)
                        card.qty -= 1
                    return card
                })
            }
            else {
                auxDeckBench2 = auxDeckBench.filter(card => {
                    return (card.qty > 1 || (card.qty === 1 && card.identifier !== actualCard.identifier))
                })
            }
            props.setBenchDeck(auxDeckBench2)

            let done = false
            let cardIndex = 0
            let newBench = auxBench.map(card => {
                if (!done) {
                    if (card === actualCard.identifier) {
                        done = true
                    }
                    else
                        cardIndex += 1
                }
                return card
            })

            newBench.splice(cardIndex, 1)
            props.setBench(newBench)
            auxVars.bench = newBench
            props.setDataVars(auxVars)
        }

    }

    return (
        <Fragment>
            {props.fromCreate ?
                //Para CardsPanel en creacion de mazo
                <div style={imgStyle} className={(!props.isMobile) ? "deck-creation row" : "deck-creation row"}>
                    <span className="fas fa-plus-square fa-2x plus-style" onClick={handleAdd}></span>
                    <span className="fas fa-eye fa-2x eye-style" onClick={handleShow}></span>
                </div> :
                props.fromNewDeck ?
                    //Para NewDeckContainer en creacion de mazo
                    <div style={imgStyle} className={(!props.isMobile) ? "deck-creation row" : "deck-creation row"}>
                        <span className="fas fa-minus-square fa-2x minus-style border-test" onClick={handleRemove}></span>
                        <span className="fas fa-eye fa-2x eye-style border-test" onClick={handleShow}></span>
                        <label className="qtyStyle border-test">{props.card.qty}</label>
                    </div> :
                    props.fromEdit ?
                        //Para DeckInfoCardsPanel en informacion de mazo
                        <div style={imgStyle} className={(!props.isMobile) ? "deckinfo-cards row" : "deckinfo-cards row"}>
                            <span className="fas fa-eye fa-2x eye-style2 border-test" onClick={handleShow}></span>
                            <label className="qtyStyle border-test">{props.card.qty}</label>
                        </div> :
                        props.fromQR ?
                            //Para QRCardsPanel en identificacion via QR
                            <div style={imgStyle} className={(props.isMobile) ? "deckinfo-cards-m row" : "deckinfo-cards row"}>
                                <span className={props.isMobile ? "fas fa-eye eye-style2-m border-test"  : "fas fa-eye fa-2x eye-style2 border-test"} onClick={handleShow}></span>
                                <label className={props.isMobile ? "qtyStyle-m border-test" : "qtyStyle border-test"}>{props.card.qty}</label>
                            </div> :
                            //Para CardsPanel en seccion de cartas
                            <Fragment>
                                <img
                                    onClick={handleShow}
                                    src={"/img/cards" + props.card.url}
                                    alt={props.card.url}
                                    className={(!props.isMobile) ? "card-box" : "card-box-mobile"}
                                    onContextMenu={(e) => { e.preventDefault(); return false }}
                                    onMouseDown={(e) => { e.preventDefault(); return false }}
                                />
                            </Fragment>
            }

            <Modal
                size="md"
                show={show}
                onHide={handleClose}
                keyboard={false}
                centered
            >
                <img
                    src={"/img/cards" + props.card.url}
                    alt={props.card.url} className="w-100"
                    onContextMenu={(e) => { e.preventDefault(); return false }}
                    onMouseDown={(e) => { e.preventDefault(); return false }}
                />
            </Modal>
        </Fragment>
    )
}
