/***** Third Party Imports *****/
import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom"
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'
import Cookie from 'universal-cookie'

/***** Local Imports *****/
import NavBar from './components/NavBar'
import Footer from './components/Footer'
import Home from './pages/Home'
//import Shop from './pages/Shop'
import Cards from './pages/Cards'
//import CreateDeck from './pages/CreateDeck'
import Page404 from './pages/Page404'
//import DeckInfo from './pages/DeckInfo'
import Success from './pages/Success'
//import Profile from './pages/Profile'
//import Decks from './pages/Decks'
//import TournamentFinder from './pages/TournamentFinder'
//import CreateTournament from './pages/CreateTournament'
//import TournamentInformation from './pages/TournamentInformation'
//import UserQRConfirmation from './pages/UserQRConfirmation'
import { AuthContext } from './authContext'
//import { Alert } from 'react-bootstrap'

/**
 * 
 * Creación del cliente de Apollo para comunicarse con el servidor de GraphQL
 * 
 */

const client = new ApolloClient({
	uri: '/graphql',
	cache: new InMemoryCache()
})

const cookie = new Cookie()

const authClient = new ApolloClient({
	uri: '/auth/graphql',
	headers: {
		Authorization: 'Bearer ' + cookie.get('ktk')
	},
	cache: new InMemoryCache()
})

function App() {
	const [loggedIn, setLoggedIn] = useState('')

	const [topMargin, setTopMargin] = useState(70)
	const [isMobile, setUsergAgent] = useState(false)
	const [user, setUser] = useState(localStorage.getItem('konnickname') || '')
	const [role, setRole] = useState(localStorage.getItem('konrole') || '')

	useEffect(() => {
		localStorage.setItem('konnickname', user)
		localStorage.setItem('konrole', role)
	}, [user, role])

	useEffect(() => {
		const nav = document.getElementById('navbar')
		var isMobile = (navigator.userAgent.includes('Android') || navigator.userAgent.includes('iPhone'))
 
		setTopMargin(nav.clientHeight)
		setUsergAgent(isMobile)
	},[])

	return (
		<ApolloProvider client={client}>
			<AuthContext.Provider value={authClient}>
				<Router>
					<NavBar isMobile={isMobile} setLoggedIn={setLoggedIn} loggedIn={loggedIn} setUser={setUser} user={user} role={role} setRole={setRole} />
					<Switch>
						<Route exact path={['/']} render={(routeProps) => {
							return <Home match={routeProps.match} isMobile={isMobile} topMargin={topMargin} />
						}}
						/>
						 <Route exact path={['/exito']} render={(routeProps) => {
							return <Success match={routeProps} isMobile={isMobile} topMargin={topMargin} />
						}}
						/> 
					{/* 	
						<Route path={[
							'/tienda'
						]} render={(routeProps) => {
							return <Shop match={routeProps.match} isMobile={isMobile} topMargin={topMargin} />
						}}
						/>   */}
						
						<Route path={[
							'/cartas'
						]} render={(routeProps) => {
							return <Cards match={routeProps.match} isMobile={isMobile} topMargin={topMargin} />
						}}
						/>
						{/* <Route path={[
							'/perfil'
							
						]} render={(routeProps) => {

							return <Profile match={routeProps.match} isMobile={isMobile} topMargin={topMargin}/>
							
						}}
						/> */}
						{/* <Route path={[
							'/mazos'
						]} render={(routeProps) => {
							return <Decks match={routeProps.match} isMobile={isMobile} topMargin={topMargin} />
						}}
						/> */}
						{/* <Route path={[
							'/creacion'
						]} render={(routeProps) => {
							return <CreateDeck match={routeProps.match} isMobile={isMobile} topMargin={topMargin} />
						}}
						/>
						<Route path={[
							'/informacion_de_mazo'
						]} render={(routeProps) => {
							return <DeckInfo match={routeProps.match} isMobile={isMobile} topMargin={topMargin} />
						}}
						/>
						<Route path={[
							'/qr-confirmation/:team?'
						]} render={(routeProps) => {
							return <UserQRConfirmation match={routeProps.match} isMobile={isMobile} topMargin={topMargin} />
						}}
						/>
						<Route path={[
							'/torneos/creacion'
						]} render={(routeProps) => {
							return <CreateTournament match={routeProps.match} isMobile={isMobile} topMargin={topMargin} user={user} role={role} />
						}}
						/>
						<Route path={[
							'/torneos/buscador'
						]} render={(routeProps) => {
							return <TournamentFinder match={routeProps.match} isMobile={isMobile} topMargin={topMargin} role={role}  />
						}}
						/>
						<Route path={[
							'/torneos/informacion/:name'
						]} render={(routeProps) => {
							return <TournamentInformation match={routeProps.match} isMobile={isMobile} topMargin={topMargin} user={user} />
						}}
						/> */}
						<Route path="/404" render={() => {
							return <Page404 isMobile={isMobile} topMargin={topMargin} />
						}} />
						<Redirect to="/404" />
					</Switch>
					<Footer isMobile={isMobile} />
				</Router>
			</AuthContext.Provider>
		</ApolloProvider>
	)
}

export default App;
