import React from 'react'

export default function LoadingPanel(props) {

    const spinnerStyle = {
        width: "3rem",
        height: "3rem",
        borderWidth: "0.5rem"
    }

    return (
        <div className="d-flex justify-content-center mt-5 pb-5 container">
            <div className="row">
                <div className="col-12 d-flex justify-content-center">
                    <div className="spinner-border mt-5" style={spinnerStyle} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                 </div>
                <div className="d-flex justify-content-center col-12 mt-4">
                    <div>{props.fromQR ? 'CARGANDO DATOS...' : 'CARGANDO CARTAS...'}</div>
                 </div>
            </div>
        </div>
    )
}
