import React from 'react'
import { useQuery } from '@apollo/client'

import CardsCarousel from '../home/CardsCarousel'
import { GET_CARD } from '../../queries'

export default function CardsCarouselContainer(props) {

    const { loading: loadingCards, data: cardsData } = useQuery(GET_CARD, { variables: { type: "Jugador" } })
    if (loadingCards) return <div className="app-bg-yellow" />

    return (
        <div className="app-bg-yellow" style={{ height: '300px' }}>
            <div className="col-12">
                <CardsCarousel cardsData={cardsData} isMobile={props.isMobile} show={props.isMobile? 2 : 10} infiniteLoop={true}>
                    {cardsData.getCard.map(card =>
                        <div key={card.identifier} style={{ padding: 8 }} className={(!props.isMobile) ? "card-container mt-3" : "card-container-mobile mt-4"} >
                            <img
                                src={"/img/cards" + card.url}
                                alt={card.url}
                                className={(!props.isMobile) ? "card-carousel" : "card-carousel-mobile"}
                                onContextMenu={(e) => { e.preventDefault(); return false }}
                                onMouseDown={(e) => { e.preventDefault(); return false }}
                            />
                        </div>
                    )}
                </CardsCarousel>
            </div>
        </div>
    )
}
