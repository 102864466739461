import React from 'react'

export default function BannerBrand(props) {

	const banner = ["/img/banner1.jpg", "/img/banner2.jpg", "/img/banner3.jpg"]
	const bannerPhone = ["/img/banner1_phone.png", "/img/banner2_phone.png", "/img/banner3_phone.png"]

	return (
		<div className="row" style={!props.isMobile ? { paddingTop: props.topMargin } : { paddingTop: "40px" }} >
			<div className="col-12 m-0 p-0">
				<div id="banner" >
					{/* cambio a padding para ajustar imagen del banner  */}
					{(!props.isMobile)
						? <div className='banner-img'><img className="w-100" src={banner[Math.floor(Math.random() * banner.length)]} alt='banner' /></div>
						: <div className='banner-img'><img className="w-100" src={bannerPhone[Math.floor(Math.random() * bannerPhone.length)]} alt='banner' /></div>
					}
				</div>
			</div>
		</div>
	)
}
