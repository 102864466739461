import React from 'react'

import { Button } from 'react-bootstrap'
import Select from 'react-select'

import {
    typeSelect,
    countrySelect,
    shieldSelect,
    tshirtSelect,
    categorySelect,
    positionSelect,
    costSelect,
    genderSelect,
    editionSelect,

} from '../../constants'

export default function CardsFilter(props) {

    const handleTypeChange = value => {
        let newType = []
        let auxVarObj = props.varObj
        if (value === null || value.length === 0) {
            delete auxVarObj.type
            props.setType([])
        }
        else {
            value.map(val => newType.push(val.value))
            props.setType(value)
            auxVarObj.type = newType
        }
        if (Object.keys(auxVarObj).lenght !== 0) {
            props.setVarObj(auxVarObj)
        }
    }

    const handleCountryChange = value => {
        let newCountry = []
        let auxVarObj = props.varObj
        if (value === null || value.length === 0) {
            delete auxVarObj.country
            props.setCountry([])
        }
        else {
            value.map(val => newCountry.push(val.value))
            auxVarObj.country = newCountry
            props.setCountry(value)
        }
        if (Object.keys(auxVarObj).lenght !== 0) {
            props.setVarObj(auxVarObj)
        }
    }

    const handleShieldChange = value => {
        let newShield = []
        let auxVarObj = props.varObj
        if (value === null || value.length === 0) {
            delete auxVarObj.shield
            props.setShield([])
        }
        else {
            value.map(val => newShield.push(val.value))
            auxVarObj.shield = newShield
            props.setShield(value)
        }
        if (Object.keys(auxVarObj).lenght !== 0) {
            props.setVarObj(auxVarObj)
        }
    }

    const handleTshirtChange = value => {
        let newTshirt = []
        let auxVarObj = props.varObj
        if (value === null || value.length === 0) {
            delete auxVarObj.tshirt
            props.setTshirt([])
        }
        else {
            value.map(val => newTshirt.push(val.value))
            auxVarObj.tshirt = newTshirt
            props.setTshirt(value)
        }
        if (Object.keys(auxVarObj).lenght !== 0) {
            props.setVarObj(auxVarObj)
        }
    }

    const handlePositionChange = value => {
        let auxVarObj = props.varObj
        if (value === null || value.label === 'Todos') {
            delete auxVarObj.position
            props.setPosition(value)
        }
        else {
            auxVarObj.position = value.value
            props.setPosition(value)
        }
        if (Object.keys(auxVarObj).lenght !== 0) {
            props.setVarObj(auxVarObj)
        }
    }

    const handleCategoryChange = value => {
        let newCategory = []
        let auxVarObj = props.varObj
        if (value === null || value.length === 0) {
            delete auxVarObj.category
            props.setCategory([])
        }
        else {
            value.map(val => newCategory.push(parseInt(val.value)))
            auxVarObj.category = newCategory
            props.setCategory(value)
        }
        if (Object.keys(auxVarObj).lenght !== 0) {
            props.setVarObj(auxVarObj)
        }
    }

    const handleCostChange = value => {
        let newCost = []
        let auxVarObj = props.varObj
        if (value === null || value.length === 0) {
            delete auxVarObj.cost
            props.setCost([])
        }
        else {

            value.forEach(val => {
                if (val.value === 'X')
                    val.value = 30
                newCost.push(parseFloat(val.value))
            })
            auxVarObj.cost = newCost
            props.setCost(value)
        }
        if (Object.keys(auxVarObj).lenght !== 0) {
            props.setVarObj(auxVarObj)
        }
    }

    const handleGenderChange = value => {

        let auxVarObj = props.varObj
        if (value === null || value.label === 'Todos') {
            delete auxVarObj.gender
            props.setGender(value)
        }
        else {
            auxVarObj.gender = value.value
            props.setGender(value)
        }
        if (Object.keys(auxVarObj).lenght !== 0) {
            props.setVarObj(auxVarObj)
        }
    }

    const handleEditionChange = value => {
        let auxVarObj = props.varObj
        if (value === null || value.label === '') {
            delete auxVarObj.edition
            props.setEdition(value)
            props.setEdition([])
        }
        else {
            auxVarObj.edition = value.value
            props.setEdition(value)
        }
        if (Object.keys(auxVarObj).lenght !== 0) {
            props.setVarObj(auxVarObj)
        }
    }

    const handleNameChange = value => {
        let auxVarObj = props.varObj
        if (value.target.value === null || value.target.value === '') {
            delete auxVarObj.name
            props.setName('')

        }
        else {
            auxVarObj.name = value.target.value
            props.setName(value.target.value)
        }
        if (typeof auxVarObj.name !== 'undefined') {
            if (Object.keys(auxVarObj).lenght !== 0 && auxVarObj.name.lenght > 2) {
                props.setVarObj(auxVarObj)
            }
        }
    }

    const handleClean = button => {
        button.preventDefault();
        props.setCountry([])
        props.setType([])
        props.setShield([])
        props.setTshirt([])
        props.setPosition([])
        props.setCategory([])
        props.setCost([])
        props.setGender([])
        props.setEdition([])
        props.setVarObj([])
    }

    if (!props.isMobile) {
        return (
            <div style={{ paddingTop: props.topMargin + 50 }} className="container">
                <div className="row">
                    <div className="col-2">
                        <Select
                            isMulti
                            placeholder="Tipo de carta"
                            value={props.type}
                            onChange={handleTypeChange}
                            options={typeSelect}
                        />
                    </div>
                    <div className="col-2">
                        <Select
                            isMulti
                            placeholder="País"
                            value={props.country}
                            onChange={handleCountryChange}
                            options={countrySelect}
                        />
                    </div>
                    <div className="col-2">
                        <Select
                            isMulti
                            placeholder="Rareza"
                            value={props.shield}
                            onChange={handleShieldChange}
                            options={shieldSelect}
                        />
                    </div>
                    <div className="col-2">
                        <Select
                            isMulti
                            placeholder="Camiseta"
                            value={props.tshirt}
                            onChange={handleTshirtChange}
                            options={tshirtSelect}
                        />
                    </div>
                    <div className="col-4">
                        <input
                            className="w-100 input-name-card"
                            placeholder="  Nombre de la carta"
                            onChange={handleNameChange}
                        />
                    </div>

                </div>
                <div className="row">
                    <div className="col">
                        <Select
                            isMulti
                            placeholder="Costo"
                            value={props.cost}
                            onChange={handleCostChange}
                            options={costSelect}
                        />
                    </div>
                    <div className="col">
                        <Select
                            isMulti
                            placeholder="Categoría"
                            value={props.category}
                            onChange={handleCategoryChange}
                            options={categorySelect}
                        />
                    </div>
                    <div className="col">

                        <Select
                            placeholder="Posición"
                            value={props.position}
                            onChange={handlePositionChange}
                            options={positionSelect}
                        />


                    </div>
                    <div className="col">
                        <Select
                            placeholder="Género"
                            value={props.gender}
                            onChange={handleGenderChange}
                            options={genderSelect}
                        />
                    </div>

                    <div className="col">
                        <Select
                            placeholder="Edicion"
                            value={props.edition}
                            onChange={handleEditionChange}
                            options={editionSelect}
                        />
                    </div>
                    <div className="col">
                        <Button onClick={handleClean} className="CleanFilterButton app-color w-100" >
                            Limpiar
                        </Button>
                    </div>

                </div>
            </div>
        )
    } else {
        return (
            <div style={{ paddingTop: "80px" }} >
                <div className="row">
                    <div className="col">
                        <Select
                            isMulti
                            placeholder="Tipo"
                            value={props.type}
                            onChange={handleTypeChange}
                            options={typeSelect}
                        />
                    </div>
                    <div className="col">
                        <Select
                            isMulti
                            placeholder="País"
                            value={props.country}
                            onChange={handleCountryChange}
                            options={countrySelect}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Select
                            isMulti
                            placeholder="Rareza"
                            value={props.shield}
                            onChange={handleShieldChange}
                            options={shieldSelect}
                        />
                    </div>
                    <div className="col">
                        <Select
                            isMulti
                            placeholder="Camiseta"
                            value={props.tshirt}
                            onChange={handleTshirtChange}
                            options={tshirtSelect}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Select
                            isMulti
                            placeholder="Costo"
                            value={props.cost}
                            onChange={handleCostChange}
                            options={costSelect}
                        />
                    </div>
                    <div className="col">
                        <Select
                            isMulti
                            placeholder="Categoría"
                            value={props.category}
                            onChange={handleCategoryChange}
                            options={categorySelect}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Select
                            placeholder="Posición"
                            value={props.position}
                            onChange={handlePositionChange}
                            options={positionSelect}
                        />
                    </div>
                    <div className="col">
                        <Select
                            placeholder="Género"
                            value={props.gender}
                            onChange={handleGenderChange}
                            options={genderSelect}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Select
                            placeholder="Edicion"
                            value={props.edition}
                            onChange={handleEditionChange}
                            options={editionSelect}
                        />
                    </div>
                    <div className="col"><input
                        className="w-100 input-name-card"
                        placeholder="  Nombre"
                        onChange={handleNameChange}
                    />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Button onClick={handleClean} className="CleanFilterButton app-color" >
                            Limpar
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

}
