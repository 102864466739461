import React from 'react'

export default function Page404(props) {
    return (
        <div className="app-bg-image-white pb-5" style={(props.isMobile) ? { paddingTop: props.topMargin } : { paddingTop: props.topMargin * 2 }}>
            <div className="container">
                <div className="app-box">
                    <div className="row">
                        <div className="col-6 text-center align-self-center text-white">
                            <img src="/img/icons/cono.png" alt="cono" className="w-50" />
                            <h2 >¡Ups! </h2>
                            <h2> ¡Página no encontrada!</h2>
                        </div>
                        <div className="col-6">
                            <img src="/img/404.png" alt="404" className="w-100" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
