import React, { Fragment, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client';
import { CREATE_TRANSACTION } from '../mutations'

export default function Success(props) {

    const banner = ["/img/banner1.png", "/img/banner2.png", "/img/banner3.png"]
    const bannerPhone = ["/img/banner1_phone.png", "/img/banner2_phone.png", "/img/banner3_phone.png"]
    const [transaction, setTransaction] = useState({
        reference_id: '',
        payment_id: '',
        payment_status: '',
        payment_status_detail: '',
        merchant_order_id: '',
        processing_mode: ''
    })

    const [createTransaction] = useMutation(CREATE_TRANSACTION)


    useEffect(() => {
        let string = window.location
        console.log('string', string)
        let url = new URL(string)
        console.log('url', url)
        let reference = url.searchParams.get("preference_id")
        console.log('reference', reference)
        createTransaction({
            variables: {
                referenceId: url.searchParams.get("preference_id"),
                paymentId: url.searchParams.get("payment_id"),
                paymentStatus: url.searchParams.get("payment_status"),
                paymentStatusDetail: url.searchParams.get("payment_status_detail"),
                merchantOrderId: url.searchParams.get("merchant_order_id"),
                processingMode: url.searchParams.get("processing_mode"),
            }
        })
        setTransaction({
            reference_id: url.searchParams.get("preference_id"),
            payment_id: url.searchParams.get("payment_id"),
            payment_status: url.searchParams.get("payment_status"),
            payment_status_detail: url.searchParams.get("payment_status_detail"),
            merchant_order_id: url.searchParams.get("merchant_order_id"),
            processing_mode: url.searchParams.get("processing_mode"),
        })

    }, [createTransaction])

    return (
        <Fragment>
            <div className="row" style={{ paddingTop: props.topMargin }}>
                <div className="col-sm m-0 p-0">
                    <div id="banner" style={{ top: props.topMargin }}>
                        <div className='succes-msg' >
                            {(transaction.payment_status === 'approved')
                                ?
                                <div>
                                    <h2 className=" text-center thanks">¡Gracias por tu compra!</h2>
                                </div>
                                :
                                ''
                            }
                            {(transaction.payment_status === 'pending')
                                ?
                                <h2 className="w-100 text-center thanks">¡Gracias por tu compra!</h2>
                                : ''
                            }
                        </div>
                        {(!props.isMobile)
                            ? <div className='banner-img'><img src={banner[Math.floor(Math.random() * banner.length)]} alt='banner' /></div>
                            : <div className='banner-img'><img src={bannerPhone[Math.floor(Math.random() * bannerPhone.length)]} alt='banner' /></div>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
