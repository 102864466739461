export const typeSelect = [
    { value: 'Energía', label: 'Energía' },
    { value: 'Estrategia', label: 'Estrategia' },
    { value: 'Foul', label: 'Foul' },
    { value: 'Hinchada', label: 'Hinchada' },
    { value: 'Jugada', label: 'Jugada' },
    { value: 'Jugador', label: 'Jugador' }
]
export const countrySelect = [
    { value: 'Chile', label: 'Chile' },
    { value: 'Argentina', label: 'Argentina' },
    { value: 'Brasil', label: 'Brasil' },
    { value: 'Uruguay', label: 'Uruguay' },
    { value: 'Peru', label: 'Perú'},
    { value: 'Bolivia', label: 'Bolivia'}
]
export const shieldSelect = [
    { value: 'azul', label: 'Amateur' },
    { value: 'rojo', label: 'Semi profesional' },
    { value: 'amarillo', label: 'Profesional' },
    { value: 'blanco', label: 'Leyenda' },
    { value: 'verde', label: 'Clase Mundial'}
]
export const tshirtSelect = [
    { value: 'azul', label: 'Azul' },
    { value: 'rojo', label: 'Rojo' },
    { value: 'amarillo', label: 'Amarillo' },
    { value: 'blanco', label: 'Blanco' },
    { value: 'negro', label: 'Negro' },
    { value: 'verde', label: 'Verde' }
]
export const categorySelect = [
    { value: '23', label: 'sub 23' },
    { value: '20', label: 'sub 20' },
    { value: '18', label: 'sub 18' },
    { value: '15', label: 'sub 15' },
]
export const positionSelect = [
    { value: null, label: 'Todos' },
    { value: 'arquero', label: 'Arquero' },
    { value: 'defensa', label: 'Defensa' },
    { value: 'mediocampo', label: 'Mediocampo' },
    { value: 'delantero', label: 'Delantero' },
]
export const costSelect = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '10', label: '10' },
]
export const genderSelect = [
    { value: null, label: 'Todos' },
    { value: 'F', label: 'Femenino' },
    { value: 'M', label: 'Masculino' },
]

export const SizeSearch = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
]

export const SortSearch = [
    { value: 1, label: 'Mas recientes' },
    { value: 2, label: 'Mas antiguos' }
]


export const TournamentTypes = [
    {value: 'LIGA', label: 'LIGA'},
    {value: 'GRUPOS', label: 'GRUPOS'},
    {value: 'ELIMINACION', label: 'ELIMINACIÓN DIRECTA'}
]

export const TournamentLevels = [
    {value: 'NACIONAL', label: 'NACIONAL'},
    {value: 'REGIONAL', label: 'REGIONAL'},
    {value: 'LOCAL', label: 'LOCAL'}
]
export const editionSelect = [
    {value: 'El debut', label: 'El debut'},
    {value: 'Clase Mundial', label: 'Clase Mundial'},
]
