import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'

import CardModal from '../modals/CardModal'
import LoadingPanel from '../LoadingPanel'
import { GET_CARD } from '../../queries'

export default function CardsPanel(props) {

    let { varObj } = props
    const [getCards, { loading, error, data }] = useLazyQuery(GET_CARD, { variables: varObj })

    let validateChange = JSON.stringify(varObj)
    useEffect(() => {
        if (typeof varObj.name !== 'undefined' && varObj.name.length >= 3) {
            getCards()
        }
        if (typeof varObj.name === 'undefined') {
            getCards()
        }

    }, [validateChange, getCards,varObj])

    if (loading) return <LoadingPanel isMobile={props.isMobile} topMargin={props.topMargin} />
    if (error) return <div className="container text-center"><p className="m-2 bg-danger text-white ">{error.message} </p></div>
    if (props.fromCreate && data) {
        let cardsShow = data.getCard.map(card => { return card })
        let cardsNew = []
        let newCardsShow = cardsShow.forEach(card => {
            let isValid = true
            props.newDeck.forEach(auxCard => {
                //Este código no es generalizado
                // Qué pasará cuando existan más cartas que se permita tener más de 3?
                // Quizás es bueno agregar campo en la base de datos para saber cuantas cartas se permiten
                // en un solo mazo, es decir, que cada carta siga cuanto es su máximo. 
                if (auxCard.identifier !== 99) {
                    if (auxCard.qty >= 3 && card.identifier === auxCard.identifier) {
                        isValid = false
                    }
                    if (auxCard.qty >= 1 && card.identifier === auxCard.identifier && auxCard.shield === 'blanco') {
                        isValid = false
                    }
                    if (card.identifier === auxCard.identifier && auxCard.type === 'Jugador') {
                        isValid = false
                    }
                }
            })
            if (isValid) {
                cardsNew.push(card)
            }
            else {
                isValid = true
            }

        })
        return (
            <div id="wrapper justify-content-center" style={(props.isMobile) ? { paddingTop: props.topMargin } : { paddingTop: props.topMargin * 2 }} className={props.fromCreate ? "container find-deck-container wrapper justify-content-left" : "container"}>
                {typeof cardsNew === 'undefined' ? '' : cardsNew.length === 0 ? <p className="m-2 wrapper justify-content-center">No se encontraron cartas con estos filtros</p> :
                    cardsNew.map((card) => <CardModal newCardsShow={newCardsShow} setBenchDeck={props.setBenchDeck} benchDeck={props.benchDeck} bench={props.bench} setBench={props.setBench} keyTab={props.keyTab} dataVars={props.dataVars} setDataVars={props.setDataVars} cards={props.cards} setCards={props.setCards} setDeckImages={props.setDeckImages} setNewDeck={props.setNewDeck} newDeck={props.newDeck} fromCreate={props.fromCreate} key={card.identifier} card={{ identifier: card.identifier, url: card.url, type: card.type[0], name: card.name, shield: card.shield[0] }} isMobile={props.isMobile}></CardModal>)
                }
            </div>
        )

    }
    if (!props.isMobile) {
        return (
            <div className="container">
                {(typeof data === 'undefined' || data === null) ? '' : (data.getCard.length === 0 || data.getCard === null) ? <p className="m-2 wrapper justify-content-center">No se encontraron cartas con estos filtros</p> :
                    (data.getCard !== null || typeof data.getCard !== 'undefined') ? data.getCard.map((card) => {
                        return <CardModal
                            setBenchDeck={props.setBenchDeck}
                            benchDeck={props.benchDeck}
                            bench={props.bench}
                            setBench={props.setBench}
                            keyTab={props.keyTab}
                            dataVars={props.dataVars}
                            setDataVars={props.setDataVars}
                            cards={props.cards}
                            setCards={props.setCards}
                            setDeckImages={props.setDeckImages}
                            setNewDeck={props.setNewDeck}
                            newDeck={props.newDeck}
                            fromCreate={props.fromCreate}
                            key={card.identifier}
                            card={{ identifier: card.identifier, url: card.url, type: card.type[0], name: card.name }}
                            isMobile={props.isMobile} />
                    }) : ''
                }
            </div>
        )
    } else {
        return (
            <>
                {(typeof data === 'undefined' || data === null) ? '' : (data.getCard.length === 0 || data.getCard === null) ? <p className="m-2 wrapper justify-content-center">No se encontraron cartas con estos filtros</p> :
                    (data.getCard !== null || typeof data.getCard !== 'undefined') ? data.getCard.map((card) => {
                        return <CardModal
                            setBenchDeck={props.setBenchDeck}
                            benchDeck={props.benchDeck}
                            bench={props.bench}
                            setBench={props.setBench}
                            keyTab={props.keyTab}
                            dataVars={props.dataVars}
                            setDataVars={props.setDataVars}
                            cards={props.cards}
                            setCards={props.setCards}
                            setDeckImages={props.setDeckImages}
                            setNewDeck={props.setNewDeck}
                            newDeck={props.newDeck}
                            fromCreate={props.fromCreate}
                            key={card.identifier}
                            card={{ identifier: card.identifier, url: card.url, type: card.type[0], name: card.name }}
                            isMobile={props.isMobile} />
                    }) : ''
                }
            </>
        )
    }

}
