import React from 'react'

export default function Footer(props) {

    let year = new Date()
    if (!props.isMobile) {
        return (
            <>
                <div className="row footer-container">
                    <div className="col-12">
                        <div className="container" style={{ paddingTop: '6rem' }}>
                            <div className="p-5 row w-100 justify-content-center">
                                <div className="col-4">
                                    <ul>
                                        <li className='footer-li '>
                                            <a className="pl-2 pr-0 text-white" style={{ lineHeight: "50px" }} href="https://www.instagram.com/kick.on.tcg/" target="_blank" rel="noopener noreferrer">
                                                <img src="/img/icons/instagram.png" alt="ig" style={{ maxWidth: '50px' }} /> Kick.on.tcg
                                            </a>
                                        </li>
                                        <li className='footer-li'>
                                            <a className="pl-2 pr-0  text-white" style={{ lineHeight: "50px" }} href="https://www.youtube.com/channel/UCvIhI83i9ZRS3bqRfFRKr2g" target="_blank" rel="noopener noreferrer">
                                                <img className='p-0' src="/img/icons/youtube.png" alt="ig" style={{ maxWidth: '50px' }} /> Kick On TCG
                                            </a>
                                        </li>
                                        <li className='footer-li'>
                                            <a className="pl-2 pr-0  text-white" style={{ lineHeight: "50px" }} href="https://www.facebook.com/groups/3170368463004461" target="_blank" rel="noopener noreferrer">
                                                <img className='p-1' src="/img/icons/facebook.png" alt="ig" style={{ maxWidth: '50px' }} /> Comunidad Kick On Chile ⚽️
                                            </a>
                                        </li>
                                        <li className='footer-li'>
                                            <a className="pl-2 pr-0  text-white" style={{ lineHeight: "50px" }} href="https://discord.gg/frZYpdc" target="_blank" rel="noopener noreferrer">
                                                <img src="/img/icons/discord.png" alt="ig" style={{ maxWidth: '50px' }} /> Kick On
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-4 text-center">
                                    <img src="/img/escudo.png" alt="ig" className='w-75' />
                                </div>
                                <div className="col-4">
                                    <ul>
                                        <li className='footer-li'>
                                            <a className="app-link w-100" href="/doc/politicas.pdf" target="_blank" rel="noopener noreferrer">Políticas de privacidad</a>
                                        </li>
                                        <li className='footer-li'>
                                            <a className="app-link w-100" href="/doc/terminos.pdf" target="_blank" rel="noopener noreferrer">Términos de servicio</a>
                                        </li>
                                        <li className='footer-li'>
                                            <a className="app-link w-100" href="mailto:contacto@kickon.cl" target="_blank" rel="noopener noreferrer">Contáctanos</a>
                                        </li>
                                    </ul>
                                </div>
                            </div >
                        </div >
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <p>Copyright &copy; {year.getFullYear()} - Entretenimientos KO SPA</p>
                        </div>
                    </div>
                </div>

            </>
        )
    } else {
        return (
            <div className="row footer-container-mobile" style={{ paddingTop: '3rem' }}>
                <div className="col-12">
                    <div className="row pt-5" >
                        <div className="col-5">
                            <div className="row">
                                <div className="col-6 text-end p-0">
                                    <a style={{ lineHeight: "50px" }} href="https://www.instagram.com/kick.on.tcg/" target="_blank" rel="noopener noreferrer">
                                        <img src="/img/icons/instagram.png" alt="ig" style={{ maxWidth: '35px' }} />
                                    </a>
                                </div>
                                <div className="col-6 text-center p-0">
                                    <a style={{ lineHeight: "50px" }} href="https://www.youtube.com/channel/UCvIhI83i9ZRS3bqRfFRKr2g" target="_blank" rel="noopener noreferrer">
                                        <img src="/img/icons/youtube.png" alt="ig" style={{ maxWidth: '40px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-end p-0">
                                    <a style={{ lineHeight: "50px" }} href="https://www.facebook.com/groups/3170368463004461" target="_blank" rel="noopener noreferrer">
                                        <img src="/img/icons/facebook.png" alt="ig" style={{ maxWidth: '35px' }} />
                                    </a>
                                </div>
                                <div className="col-6 text-center p-0">
                                    <a style={{ lineHeight: "50px" }} href="https://discord.gg/frZYpdc" target="_blank" rel="noopener noreferrer">
                                        <img src="/img/icons/discord.png" alt="ig" style={{ maxWidth: '40px' }} />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-7" style={{ display: "flex", alignItems: "center" }}>
                            <ul>
                                <li className='footer-li'>
                                    <a className="app-link w-100" href="/doc/politicas.pdf" target="_blank" rel="noopener noreferrer">Políticas de privacidad</a>
                                </li>
                                <li className='footer-li'>
                                    <a className="app-link w-100" href="/doc/terminos.pdf" target="_blank" rel="noopener noreferrer">Términos de servicio</a>
                                </li>
                                <li className='footer-li'>
                                    <a className="app-link w-100" href="mailto:contacto@kickon.cl" target="_blank" rel="noopener noreferrer">Contáctanos</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="p-1 row w-100">
                        <div className="col text-center"> Copyright &copy; {year.getFullYear()} - Entretenimientos KO SPA </div>
                    </div>
                </div>
            </div>
        )
    }
}
