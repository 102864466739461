import React, { useState } from 'react'

import CardsFilter from '../components/cards/CardsFilter'
import CardsPanel from '../components/cards/CardsPanel'

export default function Cards(props) {

    const [varObj, setVarObj] = useState({})
    const [type, setType] = useState(null)
    const [country, setCountry] = useState('')
    const [category, setCategory] = useState('')
    const [tshirt, setTshirt] = useState('')
    const [shield, setShield] = useState('')
    const [position, setPosition] = useState('')
    const [cost, setCost] = useState(1)
    const [gender, setGender] = useState('')
    const [edition, setEdition] = useState('')
    const [name, setName] = useState('')

    return (
        <div className=" row app-bg-yellow vh-80">
            <div className="col-2">
                <img className="envelope" src="img/TexturaSobreIzquierda.png" alt="textura-izq" />
            </div>
            <div className="col-8">
                <CardsFilter isMobile={props.isMobile} topMargin={props.topMargin} varObj={varObj} type={type} name={name}
                    country={country} category={category} tshirt={tshirt} shield={shield} position={position} cost={cost} gender={gender} edition={edition} setVarObj={setVarObj} setType={setType}
                    setCountry={setCountry} setCategory={setCategory} setTshirt={setTshirt} setShield={setShield} setPosition={setPosition} setCost={setCost} setGender={setGender} setEdition={setEdition} setName={setName}
                />
                {Object.keys(varObj).length !== 0 ?
                    <CardsPanel isMobile={props.isMobile} topMargin={props.topMargin} varObj={varObj} />
                    : ''}
            </div>
            <div className="col-2">
                <img className="envelope " src="img/TexturaSobreDerecha.png" alt="textura-der" />
            </div>
        </div>

    )
}
