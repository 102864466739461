import { gql } from '@apollo/client';

export const CREATE_TRANSACTION_DATA = gql`
    mutation createTransactionData(
        $name: String!
        $lastName: String!
        $dni: String!
        $phone: String!
        $email: String!
        $address: String!
        $subAddress: String!
        $department: String
        $promoIdentifier: Int!
        $quantity: Int!
        $promoCode: String
        $mercadopagoId: String!
        $dataType: String
        $teamName: String
    ){
        createTransactionData(
            name: $name
            lastName: $lastName
            dni: $dni
            phone: $phone
            email: $email
            address: $address
            subAddress: $subAddress
            department: $department
            promoIdentifier: $promoIdentifier
            quantity: $quantity
            promoCode: $promoCode
            mercadopagoId: $mercadopagoId
            dataType: $dataType
            teamName: $teamName
        ){
            _id
            name
            lastName
            dni
            phone
            email
            address
            subAddress
            department
            promoIdentifier
            quantity
            promoCode
            mercadopagoId
            dataType
            teamName
        }
    }
`
export const CREATE_TRANSACTION = gql`
    mutation createTransaction(
        $referenceId: String!
        $paymentId: String!
        $paymentStatus: String
        $paymentStatusDetail: String
        $merchantOrderId: String!
        $processingMode: String
    ){
        createTransaction(
            referenceId: $referenceId
            paymentId: $paymentId
            paymentStatus: $paymentStatus
            paymentStatusDetail: $paymentStatusDetail
            merchantOrderId: $merchantOrderId
            processingMode: $processingMode
        ){
            _id
            referenceId
            paymentId
            paymentStatus
            paymentStatusDetail
            merchantOrderId
            processingMode
        }
    }
`
export const EDIT_PROMO = gql`
    mutation editPromo(
        $identifier:Int, 
        $description: String, 
        $qty: Int, 
        $price:Int, 
        $mercadopagoId: String
        ){
            editPromo(
                identifier:$identifier, 
                description: $description, 
                qty: $qty, 
                price:$price, 
                mercadopagoId: $mercadopagoId){
            _id
            description
            qty
            price
        }
    }
`

export const CREATE_USER = gql`
    mutation createUser(
        $email: String!,
        $nickname: String!
        $password: String!
    ){
        createUser(
            email: $email,
            nickname: $nickname,
            password: $password
        ){
            _id
            lastname
            nickname
            password
        }
    }
`

export const EDIT_USER = gql`
    mutation editUser(
        $_id: ID!,
        $email: String,
        $name: String,
        $lastname: String,
        $nickname: String,
        $password: String,
        $newPassword: String,
        $profileImg: String,
        $dni: String,
        $address: String,
        $subaddress: String,
        $phone: String,
        $credencialImage: String        
    ){
        editUser(
            _id: $_id,
            email: $email,
            name: $name,
            lastname: $lastname,
            nickname: $nickname,
            password: $password,
            newPassword: $newPassword,
            profileImg: $profileImg,
            phone: $phone,
            dni: $dni,
            address: $address,
            subaddress: $subaddress,
            credencialImage: $credencialImage
        ){
            _id
            email
            name
            lastname
            nickname
            profileImg
            phone
            dni
            address
            subaddress
            credencialImage    
        }
    }
`

export const CREATE_TEAM = gql`
    mutation createTeam(
        $name: String!,
        $deckID: ID
        $logo: String
    ){
        createTeam(
            name: $name,
            deckID: $deckID,
            logo: $logo
        ){
            _id
            name
            user {
                name
            }
            deck {
                deckName
            }
            logo
        }
    }
`

export const CREATE_DECK = gql`
    mutation createDeck(
        $deckName: String,
        $img: String,
        $isPublic: Boolean!,
        $cards: [Int]
        $bench: [Int]
    ){
        createDeck(
            deckName: $deckName,
            img: $img,
            cards: $cards,
            bench: $bench,
            isPublic: $isPublic
        ){
            isPublic
            deckName
            img
            cards {
                name
            }
            bench {
                name
            }
        }
    }
`

export const EDIT_DECK = gql`
    mutation editDeck(
        $_id: ID!,
        $deckName: String,
        $img: String,
        $isPublic: Boolean!,
        $cards: [Int],
        $bench: [Int]
    ){
        editDeck(
            _id: $_id
            deckName: $deckName,
            img: $img,
            cards: $cards,
            bench: $bench,
            isPublic: $isPublic
        ){
            _id
            isPublic
            deckName
            img
            cards {
                name
            }
            bench {
                name
            }
        }
    }
`

export const EDIT_TEAM = gql`
    mutation editTeam(
        $_id: ID!
        $name: String,
        $deckID: ID,
        $logo: String
    ){
        editTeam(
            _id: $_id
            name: $name,
            deckID: $deckID,
            logo: $logo
        ){
            _id
            name
            user {
                name
            }
            deck {
                deckName
            }
            logo
        }
    }
`

export const DELETE_TEAM = gql`
    mutation deleteTeam(
        $_id: ID!
    ){
        deleteTeam(
            _id: $_id
        ){
            ok
        }
    }
`

export const CREATE_GROUP_PHASE = gql`
    mutation createGroupsPhase( $tournamentID: ID!, $participants: [TeamInput]){
        createGroupsPhase( tournamentID: $tournamentID, participants: $participants ){
            _id
        }
    }
`

export const END_TOURNAMENT = gql`
    mutation endTournament( $tournamentID: ID!){
        endTournament( tournamentID: $tournamentID ){
            _id
        }
    }
`

export const CREATE_NEXT_ELIMINATORY_PHASE = gql`
    mutation createNextEliminatoryPhase( $tournamentID: ID!, $phase: String!){
        createNextEliminatoryPhase( tournamentID: $tournamentID, phaseName: $phaseName ){
            _id
        }
    }
`

export const CREATE_TOURNAMENT = gql`
    mutation createTournament(
        $name: String!, 
        $startDate: Date!, 
        $startInscription: Date!, 
        $endInscription: Date!, 
        $maxParticipants: Int!, 
        $tournamentType: String!, 
        $playersPerGroup: Int,
        $tournamentLevel: String!,
        $description: String
        ){
            createTournament( 
                name: $name, 
                startDate: $startDate,
                startInscription: $startInscription,
                endInscription: $endInscription,
                maxParticipants: $maxParticipants,
                tournamentType: $tournamentType,
                playersPerGroup: $playersPerGroup,
                tournamentLevel: $tournamentLevel,
                description: $description  
                ){
                _id
        }
    }
`

export const ADD_MATCH_DATE = gql`
    mutation addMatchDate(
        $tournamentID: ID!,
        $matchID: ID!,
        $date: Date!
        ){
            addMatchDate(
            tournamentID: $tournamentID,
            matchID: $matchID,
            date: $date
                ){
                _id
        }
    }
`

export const END_GROUP_PHASE = gql`
    mutation endGroupPhase(
        $tournamentID: ID!,
        $teams: [ID!]!,
        $phaseName: String!
        ){
            endGroupPhase(
                tournamentID: $tournamentID,
                teams: $teams,
                phaseName: $phaseName
                ){
                _id
        }
    }
`

export const EDIT_MATCH = gql`
    mutation editMatch(
        $tournamentID: ID!,
        $matchID: ID!,
        $winner: ID!,
        $draw: Boolean!,
        $GV: Int!,
        $GL: Int!,
        $yellowVisit: [Int],
        $yellowLocal: [Int],
        $redVisit: [Int],
        $redLocal: [Int],
        $hasPenalties: Boolean!,
        $PGV: Int,
        $PGL: Int
        ){
            editMatch(
                tournamentID: $tournamentID,
                matchID: $matchID,
                winner: $winner,
                draw: $draw,
                GV: $GV,
                GL: $GL,
                yellowVisit: $yellowVisit,
                yellowLocal: $yellowLocal,
                redVisit: $redVisit,
                redLocal: $redLocal,
                hasPenalties: $hasPenalties,
                PGV: $PGV,
                PGL: $PGL
                ){
                _id
        }
    }
`

export const ADD_INSCRIPTION = gql`
    mutation addInscription(
        $tournamentID: ID!, 
        ){
            addInscription( 
                tournamentID: $tournamentID
                ){
                _id
                participants {
                    _id
                    name
                    user {
                      _id
                      name
                      lastname
                      nickname
                      coachID
                    }
                    deck {
                      _id
                      deckName
                      cards {
                        _id
                        identifier
                        type
                        name
                        cost
                        url
                      }
                      bench {
                        _id
                        identifier
                        type
                        name
                        cost
                        url
                      }
                      isPublic
                      dqi
                      img
                    }
                    logo
                  }
        }
    }
`