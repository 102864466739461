import { gql } from '@apollo/client'

export const GET_PROMO = gql`
  query GET_PROMO($id: ID, $identifier: Int!) {
    getPromo(_id: $id, identifier: $identifier) {
      _id
      description
      photo_uri
      qty
    }
  }
`;

export const GET_TRANSACTION_DATA = gql`
  query GET_TRANSACTION_DATA(
    $id: ID,
    $name: String, 
    $lastName: String, 
    $promoIdentifier: Int, 
    $promoCode: String, 
    $mercadopagoId: String){
      getTransactionData(
        _id: $id,
        name: $name, 
        lastName: $lastName, 
        promoIdentifier: $promoIdentifier, 
        promoCode:$promoCode,
        mercadopagoId: $mercadopagoId){
          _id
          name
          lastName
          dni
          address
          quantity
          promoCode
          email
          promoIdentifier
        }
      }
`
export const GET_CARD = gql`
  query GET_CARD(
    $identifier: Int, 
    $type: [String],
    $name: String,
    $country: [String],
    $flag: String,
    $category: [Int],
    $hability: String,
    $playerHasHability: Int
    $shield: [String],
    $position: [String],
    $tshirt: [String],
    $defense: Int,
    $attack: Int,
    $gender: String,
    $cost: [Float],
    $anf7: Boolean,
    $designer: String,
    $url: String,
    $edition: String,
    ){
      getCard(
        identifier: $identifier, 
        type: $type,
        name: $name,
        country: $country,
        flag: $flag,
        category: $category,
        hability: $hability,
        playerHasHability: $playerHasHability,
        shield: $shield,
        position: $position,
        tshirt: $tshirt,
        defense: $defense,
        attack: $attack,
        gender: $gender,
        cost: $cost,
        anf7: $anf7,
        designer: $designer
        url: $url
        edition: $edition
      ){
          identifier
          type
          name
          country
          flag
          category
          description
          hability
          playerHasHability
          qtyTshirts
          shield
          position
          tshirt
          defense
          attack
          plusAttack
          potentialAttack
          gender
          cost
          anf7
          designer
          url
          edition
      }
    }
`

export const GET_SHOP = gql`
  query GET_SHOP($id: ID) {
    getShop(_id: $id) {
      _id
      name
      contact
      url
      logo
    }
  }
`

export const GET_USER = gql`
  query GET_USER($_id: ID) {
    getUser(_id: $_id) {
      _id
      name
      lastname
      nickname
      profileImg
      coachID
      phone
      address
      subaddress
      dni
      credencialImage
    }
  }
`

export const GET_USER_QR = gql`
  query GET_USER_QR($_id: ID) {
    getUserQR(_id: $_id) {
      _id
      name
      lastname
      nickname
      profileImg
      coachID
      dni
    }
  }
`

export const GET_DECK = gql`
  query GET_DECK($_id: ID) {
    getDeck(_id: $_id) {
      _id
      deckName
    }
  }
`

export const GET_DECK_QR = gql`
  query GET_DECK_QR($_id: ID) {
    getDeckQR(_id: $_id) {
      _id
      deckName
      cards {
        identifier
        name
      }
      bench {
        identifier
        name
      }
    }
  }
`

export const GET_TEAM = gql`
  query GET_TEAM($_id: ID) {
    getTeam(_id: $_id) {
      _id
      name
      deck {
        _id
        deckName
        isPublic
        img
        updatedAt
        cards {
          _id
          identifier
          type
          name
          country
          flag
          category
          lessCategory
          greaterCategory
          description
          hability
          qtyHability
          playerHasHability
          shield
          rarityValue
          position
          tshirt
          qtyTshirts
          defense
          attack
          plusAttack
          potentialAttack
          gender
          cost
          anf7
          designer
          url
          originDistance
          angleDistance
          totalPower
          totalVersatility
          cqi
          cqiPot
      }
    }
      user {
        _id
        name
        lastname
        nickname
        profileImg
        coachID
        phone
        address
        subaddress
        dni
      }
      logo
    }
  }
`

export const GET_TEAM_QR = gql`
  query GET_TEAM_QR($_id: ID) {
    getTeamQR(_id: $_id) {
      _id
      name
      deck {
        _id
        deckName
        isPublic
        img
        updatedAt
        cards {
          _id
          identifier
          type
          name
          country
          flag
          category
          lessCategory
          greaterCategory
          description
          hability
          qtyHability
          playerHasHability
          shield
          rarityValue
          position
          tshirt
          qtyTshirts
          defense
          attack
          plusAttack
          potentialAttack
          gender
          cost
          anf7
          designer
          url
          originDistance
          angleDistance
          totalPower
          totalVersatility
          cqi
          cqiPot
        }
        bench {
          _id
          identifier
          type
          name
          country
          flag
          category
          lessCategory
          greaterCategory
          description
          hability
          qtyHability
          playerHasHability
          shield
          rarityValue
          position
          tshirt
          qtyTshirts
          defense
          attack
          plusAttack
          potentialAttack
          gender
          cost
          anf7
          designer
          url
          originDistance
          angleDistance
          totalPower
          totalVersatility
          cqi
          cqiPot
        }
      }
      user {
        _id
        name
        lastname
        nickname
        profileImg
        coachID
        phone
        address
        subaddress
        dni
      }
      logo
    }
  }
`


export const GET_DECKS = gql`
  query GET_DECKS($deckName: String, $nickname: String, $isPublic: Boolean, $page: Int, $size: Int, $sorted: Int) {
    getDecks(deckName: $deckName, nickname: $nickname, isPublic: $isPublic, page: $page, size: $size, sorted: $sorted) {
      decks {
        _id
        user {
          _id
          nickname
        }
        deckName
        isPublic
        img
        updatedAt
        cards {
          _id
          identifier
          type
          name
          country
          flag
          category
          lessCategory
          greaterCategory
          description
          hability
          qtyHability
          playerHasHability
          shield
          rarityValue
          position
          tshirt
          qtyTshirts
          defense
          attack
          plusAttack
          potentialAttack
          gender
          cost
          anf7
          designer
          url
          originDistance
          angleDistance
          totalPower
          totalVersatility
          cqi
          cqiPot
        }
        bench {
          _id
          identifier
          type
          name
          country
          flag
          category
          lessCategory
          greaterCategory
          description
          hability
          qtyHability
          playerHasHability
          shield
          rarityValue
          position
          tshirt
          qtyTshirts
          defense
          attack
          plusAttack
          potentialAttack
          gender
          cost
          anf7
          designer
          url
          originDistance
          angleDistance
          totalPower
          totalVersatility
          cqi
          cqiPot
        }
        playCards
        strategyCards
        foulCards
        fansCards
        energyCards
        maxAttack
        maxDefense
        amateurCards
        semiProCards
        proCards
        legendCards
        playerCards
        offensePlayerCards
        defensePlayerCards
        midFieldPlayerCards
        goalKeeperCards
        dqi
        energyCosts {
          cost0
          cost1
          cost2
          cost3
          cost4
          cost5
          cost6
        }
      }
      totalPages
      
    }
  }
`

export const GET_TOURNAMENT = gql`
  query GET_TOURNAMENT($_id: ID!) {
    getTournament(_id: $_id) {
      _id
        organizer {
          _id
          nickname
        }
        phase
        name
        startDate
        startInscription
        endInscription
        maxParticipants
        playersPerGroup
        tournamentLevel
        tournamentType
        participants {
          _id
          name
          user {
            _id
            nickname
          }
          logo
        }
        groups {
          _id
          word
          teams {
            _id
            team {
              _id
              name
            }
            PJs
            PGs
            PPs
            PEs
            GF
            GC
            difG
            score
          }
        }
        playoffs {
          _id
          phase
          phaseName
        }
        results {
          _id
          visit {
            _id
            name
            deck {
              _id
              deckName
              cards {
                identifier
                type
                name
                url
              }
              bench {
                identifier
                type
                name
                url
              }
            }
            logo
          }
          local {
            _id
            name
            deck {
              _id
              deckName
              cards {
                identifier
                type
                name
                url
              }
              bench {
                identifier
                type
                name
                url
              }
            }
            logo
          }
          groupLetter
          GL
          GV
          PGL
          PGV
          isDone
          matchDate
          phase
          yellowVisit {
            name
          }
          yellowLocal {
            name
          }
          redVisit {
            name
          }
          redLocal {
            name
          }
          draw
          hasPenalties
          losser {
            _id
            name
          }
          winner {
            _id
            name
          }
        }
    }
  }
`

export const GET_TOURNAMENTS = gql`
  query GET_TOURNAMENTS($_id: ID, $name: String, $organizer: String, $tournamentType: String, $page: Int) {
    getTournaments(_id: $_id, name: $name, organizer: $organizer, tournamentType: $tournamentType, page: $page) {
      tournaments {
        _id
        organizer {
          _id
          nickname
        }
        phase
        name
        startDate
        startInscription
        endInscription
        maxParticipants
        playersPerGroup
        tournamentLevel
        tournamentType
      }
      totalPages
    }
  }
`