import React from 'react'
import BannerBrand from '../components/home/BannerBrand'
import StoresBox from '../components/home/StoresBox'
import VideoLearnToPlay from '../components/home/VideoLearnToPlay'
import CardsCarouselContainer from '../components/containers/CardsCarouselContainer'

export default function Home(props) {

   return (
      <>
         <BannerBrand isMobile={props.isMobile} topMargin={props.topMargin} />
         <StoresBox isMobile={props.isMobile} topMargin={props.topMargin} />
         <VideoLearnToPlay isMobile={props.isMobile} />
         <CardsCarouselContainer isMobile={props.isMobile} />
      </>
   )
}
