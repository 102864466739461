import React from 'react'

export default function VideoLearnToPlay(props) {

    return (
        <div className="row">
            <div className="col-12">
                <h3 id="howtoplay" className="m-0 pt-4 pr-4 pl-4 w-100 text-center bg-black text-bold app-color">APRENDE A JUGAR</h3>

                <div id="carouselExampleControls" className="carousel slide bg-black text-center" data-bs-ride="carousel" style={{ paddingTop: 21, paddingBottom: 50 }}>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <iframe width={!props.isMobile ? "533" : " "} height={!props.isMobile ? "400" : ""} src="https://www.youtube.com/embed/RWoUzmBuUvM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <div className="carousel-item">
                            <iframe width={!props.isMobile ? "533" : " "} height={!props.isMobile ? "400" : ""} src="https://www.youtube.com/embed/wBW09KVUXDk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <div className="carousel-item">
                            <iframe width={!props.isMobile ? "533" : " "} height={!props.isMobile ? "400" : ""} src="https://www.youtube.com/embed/QsDGF2Md_vA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previo</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Siguiente</span>
                    </button>
                    <div className='cuadrado' style={{ height: '40px', position: 'relative', float: 'right', bottom: '-10px', display: "flex", alignItems: "center", paddingLeft: "10px", paddingRight: "10px" }}><span style={{ lineHeight: "100px", textAlign: "center", fontWeight: "bold" }}> ¡Diseños de cartas únicos!</span></div>
                </div>
            </div>
        </div>

    );
}

